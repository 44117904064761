import styled, {keyframes} from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';

interface Props {
  animate: boolean;
  position: boolean;
}

export const HeaderWrapper = styled.header`
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
  max-width: 1370px;
  top: 40px;
  left: 50%;
  transform: translate(-50%);
  padding: 0 30px;
  margin: 0 auto;
  height: 50.28px;
  z-index: ${({theme}) => theme.zIndex.medium};

  ${BREAKPOINTS.L} {
    justify-content: flex-end;
    align-items: center;
    width: 75vw;
  }
`;

const logoAnimation = keyframes`
  from {
    left: 50%;
  }

  to {
    left: 0%;
    transform: translateX(0);
  }
`;

export const LogoWrapper = styled.div<Props>`
  display: flex;
  position: absolute;
  justify-content: center;
  width: 75vw;
  top: ${(Props) => (Props.animate ? '40px' : '10px')};
  transition: all 0.8s;
  left: 50%;
  transform: translate(-50%);
  padding: 0 30px;
  margin: 0 auto;
  z-index: ${({theme}) => Number(theme.zIndex.high) + 1};

  ${BREAKPOINTS.L} {
    max-width: 1370px;
    top: 40px;
    div {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      animation: ${logoAnimation} 400ms ease-in 8400ms normal both;
      margin-left: ${(Props) => (Props.position ? '31px' : '0px')};
      /* margin-left: 31px; */
    }
  }
`;

export const NavigationWrapper = styled.div`
  display: none;

  ${BREAKPOINTS.L} {
    display: block;
  }
`;
