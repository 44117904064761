import React from 'react';
import {useMode} from 'context/mode';
import {
  NavigationItem,
  NavigationList,
  Separator,
  LanguageArrow,
} from './styled';
import {useTranslation} from 'react-i18next';
import {LanguageSelect} from 'components/language-select';

import {ReactComponent as ArrowUp} from 'assets/icons/arrow-up.svg';

export const Navigations = (): JSX.Element => {
  const {t} = useTranslation();

  const context = useMode();
  const {view, viewLanguage, visible} = context;

  const toggleMenu = (e: React.MouseEvent): void => {
    e.stopPropagation();
    viewLanguage();
  };

  return (
    <nav data-testid="navigations">
      <NavigationList visible={visible}>
        <NavigationItem onClick={() => context.toggleAboutUs()}>
          {t('text.aboutUs')}
        </NavigationItem>
        <Separator />
        <NavigationItem onClick={()=>context.toggleContacts(true)}>
          {t('text.contact')}
        </NavigationItem>
        <Separator />
        <NavigationItem id='navigation_item' onClick={toggleMenu}>
          {t('text.language')}
          <LanguageArrow id='language_arrow' view={view}>
            <ArrowUp id='arrow_up'/>
          </LanguageArrow>
          <LanguageSelect />
        </NavigationItem>
      </NavigationList>
    </nav>
  );
};
