import React, {useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useMode} from 'context/mode';
import {useInView} from 'react-intersection-observer';

import {
  SingleBlock,
  LeftSide,
  NumberOfBlock,
  NumberOfBlockBorder,
  OwnersPhoto,
  Title,
  Separator,
  WhoText,
  OwnersContact,
  Kyrylo,
  Name,
  WhoIs,
  Social,
  ThinText,
} from './styled';

import photo from 'assets/icons/owners.jpg';
import {ReactComponent as Line} from 'assets/icons/separation_line.svg';
import {ReactComponent as LinkedIn} from 'assets/icons/linked-in.svg';
import {ReactComponent as Instagram} from 'assets/icons/inst.svg';
import {ReactComponent as Facebook} from 'assets/icons/facebook.svg';
import {ReactComponent as Twitter} from 'assets/icons/twitter.svg';

export const Section1 = (): JSX.Element | null => {
  const {t} = useTranslation();
  const context = useMode();
  const {toggleActiveTab1} = context;

  const {ref, inView} = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    toggleActiveTab1(inView);
  }, [inView]);


  return (
    <div>
      <SingleBlock>
        <LeftSide>
          <NumberOfBlock>01</NumberOfBlock>
          <NumberOfBlockBorder>01</NumberOfBlockBorder>
          <Title>{t('text.whoAreWe')}</Title>
          <Separator>
            <Line />
          </Separator>
          <WhoText>
            <Trans
              t={t}
              i18nKey="text.whoAreWeDescr"
              components={[<ThinText key={0} />]}
            />
          </WhoText>
        </LeftSide>
        <div ref={ref}>
          <OwnersPhoto>
            <img src={photo}></img>
          </OwnersPhoto>
          <OwnersContact>
            <Kyrylo>
              <Name>{t('text.kyrylo')}</Name>
              <WhoIs>{t('text.architect')}</WhoIs>
              <Social>
                <a
                  href="https://www.linkedin.com/in/kirillyevtushenko/"
                  target="blank"
                  rel="noreferrer"
                >
                  <LinkedIn />
                </a>
                <a
                  href="https://instagram.com/kirillyevtushenko?igshid=YmMyMTA2M2Y="
                  target="blank"
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
                <a
                  href="https://www.facebook.com/twinsyevtushenko"
                  target="blank"
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
                <a
                  href="https://twitter.com/YevtushenkoK"
                  target="blank"
                  rel="noreferrer"
                >
                  <Twitter />
                </a>
              </Social>
            </Kyrylo>
            <div>
              <Name>{t('text.artem')}</Name>
              <WhoIs>{t('text.architect')}</WhoIs>
              <Social>
                <a
                  href="https://www.linkedin.com/in/artem-yevtushenk/"
                  target="blank"
                  rel="noreferrer"
                >
                  <LinkedIn />
                </a>
                <a
                  href="https://instagram.com/artiomyevtushenko?igshid=YmMyMTA2M2Y="
                  target="blank"
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
                <a
                  href="https://www.facebook.com/artiom.yevtushenko/"
                  target="blank"
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
                <a
                  href="https://twitter.com/YevtushenkoAN"
                  target="blank"
                  rel="noreferrer"
                >
                  <Twitter />
                </a>
              </Social>
            </div>
          </OwnersContact>
        </div>
      </SingleBlock>
    </div>
  );
};
