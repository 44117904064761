import styled from 'styled-components';
import {ReactComponent as TwinsLogo} from 'assets/icons/logo-twins.svg';
import {ReactComponent as Flag} from 'assets/icons/line-in-logo.svg';
import {BREAKPOINTS} from 'styles/breakpoints';

interface Props {
  animate: boolean;
}

export const LogoWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  width: 239px;
  transform: ${(Props) => (Props.animate ? 'scale(1)' : 'scale(0.6)')};
  transition: all 0.8s;
  span {
    display: inline;
    color: ${({theme}) => theme.colors.white};
    font-family: ${({theme}) => theme.fonts.regular};
    font-size: 14px;
    white-space: break-spaces;
    flex-shrink: 0.5;
  }

  ${BREAKPOINTS.L} {
    span {
      font-size: 16px;
    }
  }
  ${BREAKPOINTS.XL} {
    width: 200px;
    span {
      font-size: 12px;
    }
  }
  ${BREAKPOINTS.XXL} {
    width: 239px;
    span {
      font-size: 16px;
    }
  }
`;

export const Twins = styled(TwinsLogo)`
  width: 94px;
  height: 36px;
  flex-shrink: 0;

  ${BREAKPOINTS.L} {
    width: 103px;
    height: 40px;
  }
  ${BREAKPOINTS.XL} {
    width: 90px;
    height: 40px;
  }
  ${BREAKPOINTS.XXL} {
    width: 103px;
    height: 40px;
  }
`;

export const UkraineFlag = styled(Flag)`
  margin: 0 16px;
  height: 36px;
  flex-shrink: 0;

  ${BREAKPOINTS.L} {
    height: 40px;
  }
  ${BREAKPOINTS.XL} {
    height: 30px;
  }
  ${BREAKPOINTS.XXL} {
    height: 40px;
  }
`;

export const BoldText = styled.span`
  && {
    font-family: ${({theme}) => theme.fonts.bold};
  }
`;
