import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';

export const SingleBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  margin-bottom: 150px;
  padding-left: 12px;

  ${BREAKPOINTS.L} {
    flex-direction: row;
    margin-bottom: 320px;
  }
`;

export const LeftSide = styled.div`
  margin-bottom: 20px;
  ${BREAKPOINTS.L} {
    width: 370px;
  }
`;

export const NumberOfBlockBorder = styled.h4`
  position: absolute;
  margin-top: 20px;
  left: 0;
  font-family: ${({theme}) => theme.fonts.regular};
  font-weight: 700;
  font-size: 150px;
  letter-spacing: -0.05em;
  line-height: 250px;
  z-index: -10;

  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px transparent;

  ${BREAKPOINTS.S} {
    font-size: 300px;
    margin-top: 0px;
  }
  ${BREAKPOINTS.M} {
    margin-top: 195px;
  }
`;

export const NumberOfBlock = styled.h4`
  position: absolute;
  margin-top: 20px;
  left: 0;
  font-family: ${({theme}) => theme.fonts.regular};
  font-weight: 700;
  font-size: 150px;
  letter-spacing: -0.05em;
  line-height: 250px;
  z-index: -10;

  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.03)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${BREAKPOINTS.S} {
    font-size: 300px;
    margin-top: 0px;
  }
  ${BREAKPOINTS.M} {
    margin-top: 195px;
    
  }
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
  font-family: ${({theme}) => theme.fonts.regular};
  margin-top: 150px;
  margin-bottom: 30px;
  white-space: normal;

  ${BREAKPOINTS.S} {
    margin-top: 184px;
    font-size: 64px;
  }
  ${BREAKPOINTS.M} {
    margin-top: 345px;
    max-width: 1000px;
  }
  /* ${BREAKPOINTS.XL} {
    margin-top: 25vh;
    max-width: 1000px;
  }
  ${BREAKPOINTS.XXL} {
    margin-top: 345px;
    max-width: 1000px;
  } */
`;

export const Separator = styled.div`
  margin-bottom: 40px;
`;

export const WhoText = styled.div`
  width: 294px;
  white-space: normal;
  font-family: ${({theme}) => theme.fonts.bold};
  opacity: 1;
`;

export const ThinText = styled.span`
  color: ${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.regular};
  white-space: normal;
  opacity: 0.7;
`;

export const OwnersPhoto = styled.div`
  margin-bottom: 24px;

  img {
    width: 300px;
    height: 300px;
  }

  ${BREAKPOINTS.S} {
    img {
      width: 600px;
      height: 600px;
    }
  }
`;

export const OwnersContact = styled.div`
  display: flex;
`;

export const Kyrylo = styled.div`
  margin-right: 25px;
  ${BREAKPOINTS.S} {
    margin-right: 130px;
  }
`;

export const Name = styled.div`
  font-family: ${({theme}) => theme.fonts.regular};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
  ${BREAKPOINTS.S} {
    font-size: 24px;
  }
`;

export const WhoIs = styled.div`
  font-family: ${({theme}) => theme.fonts.regular};
  font-size: 16px;
  opacity: 0.7;
  margin-bottom: 50px;
  /* width: 60px; */
  width: 55%;
  white-space: normal;
  ${BREAKPOINTS.S} {
    width: 100%;
  }
`;

export const Social = styled.div`
  display: flex;
  cursor: pointer;
  svg {
    margin-right: 24px;
    opacity: 0.8;
    transition: 0.5s;
    :hover {
      opacity: 1;
      transition: 0.5s;
    }
  }
`;
