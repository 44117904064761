import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';

import {Benefits, Number, Seven, Vr360} from './styled';

export const RightNumbers = (): JSX.Element | null => {
  const {t} = useTranslation();
  const [count, setCount] = useState<number>(0);

  const {ref, inView} = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (inView) {
        setCount((count) => (count < 24 ? count + 1 : count));
      } else {
        setCount(0);
      }
    }, 45);
    return () => clearInterval(interval);
  }, [inView]);

  return (
    <div ref={ref}>
      <Benefits>
        <Vr360>{t('text.access')}</Vr360>
        <Number>{count}</Number>
        <Seven>/7</Seven>
      </Benefits>
    </div>
  );
};
