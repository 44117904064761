import React, {useEffect} from 'react';
import {createPortal} from 'react-dom';
import {
  SlidesWrapper,
  RightArrow,
  LeftArrow,
  SpinnerWrapper,
  LeftArrowSvg,
  RightArrowSvg,
} from './styled';
import {Swiper, useSwiper} from 'swiper/react';
import 'swiper/css';

import {useMode} from 'context/mode';

import {ReactComponent as Spinner} from 'assets/icons/spinner.svg';

interface Props {
  children: JSX.Element[];
  onSlideChange: (swiper: any) => void;
  isLangUpdated: boolean;
}

const ArrowPortal = ({children}: {children: JSX.Element}): JSX.Element => {
  const container = document.querySelector('#root');

  if (container === null) {
    return children;
  }

  return createPortal(children, container);
};

const Slides = ({isLangUpdated}: {isLangUpdated: boolean}): JSX.Element => {
  const swiper = useSwiper();

  const context = useMode();
  const {
    visible,
    setNextSlide,
    setPrevSlide,
    nextSlideOnPreview,
    toggleSpinner,
  } = context;

  // @Fixme refactor the duplication
  const slides: string[] = [
    'https://kuula.co/share/collection/799vy?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
    'https://kuula.co/share/collection/799vt?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
    'https://kuula.co/share/collection/799nN?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
    'https://kuula.co/share/collection/7999N?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
    'https://kuula.co/share/collection/79994?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
    'https://kuula.co/share/collection/79JPS?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
  ];

  const prev = (): void => {
    swiper.slidePrev();
    setPrevSlide(slides);
  };

  const next = (): void => {
    swiper.slideNext();
    setNextSlide(slides);
  };

  if (isLangUpdated) {
    swiper.update();
    toggleSpinner(false);
  }

  useEffect(() => {
    swiper.update();
  }, []);

  useEffect(() => {
    if (nextSlideOnPreview) {
      swiper.slideNext();
      setNextSlide(slides);
    }
  }, [nextSlideOnPreview]);

  return (
    <ArrowPortal>
      <SlidesWrapper>
        <LeftArrow onClick={prev} visible={visible}>
          <LeftArrowSvg />
        </LeftArrow>
        <RightArrow onClick={next} visible={visible}>
          <RightArrowSvg />
        </RightArrow>
      </SlidesWrapper>
    </ArrowPortal>
  );
};

export const Slider = ({
  children,
  onSlideChange,
  isLangUpdated,
}: Props): JSX.Element => {
  const context = useMode();
  const {viewSpinner} = context;

  return (
    <>
      {viewSpinner && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      <Swiper
        loop={false}
        onSlideChange={onSlideChange}
        onUpdate={onSlideChange}
        speed={1000}
        onReachEnd={() => console.log('spin')}
        style={{background: 'black'}}
      >
        {children}
        <Slides isLangUpdated={isLangUpdated} />
      </Swiper>
    </>
  );
};
