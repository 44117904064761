import React, {useEffect} from 'react';
import {KuulaWrapper} from './styled';
import {SwiperSlide} from 'swiper/react';
import {useMode} from 'context/mode';

interface Props {
  src: string;
}

export const Slide = ({src }: Props): JSX.Element => {
  const context = useMode();
  const {toggleSpinner, visible} = context;
  

  useEffect(() => {
    const spinnerDelay = setTimeout((): void => {
      toggleSpinner(false);
    }, 850);
    return () => clearInterval(spinnerDelay);
  }, []);


  return (
    <>
      <SwiperSlide>
        <KuulaWrapper
          zoom={visible}
          frameBorder="0"
          allow="xr-spatial-tracking; gyroscope; accelerometer;"
          allowFullScreen
          scrolling="yes"
          src={src}
        />
      </SwiperSlide>
    </>
  );
};
