import React, {useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useMode} from 'context/mode';
import {useInView} from 'react-intersection-observer';

import {
  Separator,
  SingleBlock4,
  NumberWrapper,
  NumberOfBlock4,
  NumberOfBlock4Border,
  ContactTitle,
  ContactText,
  ContactUsWrapper,
  ContactDescr,
  ContactUs,
  ContactIcon,
  ContactData,
  ThinText,
} from './styled';

import {ReactComponent as Line} from 'assets/icons/separation_line.svg';
import {ReactComponent as Mail} from 'assets/icons/email-icon.svg';
import {ReactComponent as WhatsApp} from 'assets/icons/whatsapp.svg';

export const Section4 = (): JSX.Element | null => {
  const {t} = useTranslation();
  const context = useMode();
  const {toggleActiveTab4} = context;

  const {ref, inView} = useInView({
    threshold: 0.6,
  });

  useEffect(() => {
    toggleActiveTab4(inView);
  }, [inView]);

  return (
    <div id="contactus" ref={ref}>
      <SingleBlock4>
        <NumberWrapper>
          <NumberOfBlock4 >04</NumberOfBlock4>
          <NumberOfBlock4Border>04</NumberOfBlock4Border>
        </NumberWrapper>
        <ContactText>
          <ContactTitle>{t('text.contactUs')}</ContactTitle>
          <Separator>
            <Line />
          </Separator>
          <ContactDescr>
            <Trans
              t={t}
              i18nKey="text.contactUsText"
              components={[<ThinText key={0} />]}
            />
          </ContactDescr>
          <ContactUsWrapper>
            <ContactUs>
              <ContactIcon>
                <Mail />
              </ContactIcon>
              <ContactData>
                <a href="mailto:office@twins.com.ua">office@twins.com.ua</a>
              </ContactData>
            </ContactUs>
            <ContactUs>
              <ContactIcon>
                <WhatsApp />
              </ContactIcon>
              <ContactData>
                <a
                  href="https://wa.me/380953229573"
                  target="blank"
                  rel="noreferrer"
                >
                  {t('text.writeOnWatsApp')}
                </a>
              </ContactData>
            </ContactUs>
          </ContactUsWrapper>
        </ContactText>
      </SingleBlock4>
    </div>
  );
};
