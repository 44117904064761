import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';

interface ViewMenu {
  view: boolean;
}

interface Props {
  visible: boolean;
}

export const NavigationList = styled.ul<Props>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: ${({theme}) => theme.fonts.regular};
  color: ${({theme}) => theme.colors.white};
  opacity: ${(Props) => (Props.visible ? '1' : '0')};
  transition: all 0.5s;
  margin-bottom: 55px;
  ${BREAKPOINTS.M} {
    margin-bottom: 0px;
  }
  ${BREAKPOINTS.L} {
    opacity: 1;
    margin-bottom: 0px;
  }
`;

export const NavigationItem = styled.span`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  opacity: 0.7;
  transition: 0.5s;
  :hover {
    opacity: 1;
    transition: 0.5s;
  }
  ${BREAKPOINTS.L} {
    display: flex;
    font-size: 12px;

    &:not(:last-child) {
      margin-right: 72px;
    }
  }
  ${BREAKPOINTS.XXL} {
    display: flex;
    font-size: 14px;

    &:not(:last-child) {
      margin-right: 72px;
    }
  }
`;

export const Separator = styled.span`
  display: inline-block;
  margin: 0 40px;
  height: 24px;
  width: 1px;
  background-color: ${({theme}) => theme.colors.white};
  opacity: 0.7;

  ${BREAKPOINTS.L} {
    display: none;
  }
`;

export const LanguageArrow = styled.div<ViewMenu>`
  margin-left: 8px;

  svg {
    width: 12px;
    height: 6px;
    transform: ${(ViewMenu) => (ViewMenu.view ? 'rotate(-0.5turn)' : 'none')};
    transition: transform 0.3s;
  }
`;
