import React from 'react';
import {LogoWrapper, Twins, UkraineFlag, BoldText} from './styled';
import { Trans, useTranslation } from 'react-i18next';
import {useMode} from 'context/mode'

export const TwinsLogo = (): JSX.Element => {
  const { t } = useTranslation();
  const context = useMode();

  const { visibleMobileHint } = context;

  return (
    <LogoWrapper animate = {visibleMobileHint}>
      <Twins />
      <UkraineFlag />
      <span>
        <Trans t={t} i18nKey="title" components={[<BoldText key={0} />]} />
      </span>
    </LogoWrapper>
  );
};
