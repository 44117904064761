import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';
import {
  NumberOfBlock,
  NumberOfBlockBorder,
  SingleBlock,
  Title,
} from '../section1/styled';

export const SingleBlock2 = styled(SingleBlock)`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1000px;

  ${BREAKPOINTS.M} {
    display: block;
  }
  ${BREAKPOINTS.XL} {
    margin-top: 25vh;
    max-width: 1000px;
    margin-bottom: 100px;
  }
  ${BREAKPOINTS.XXL} {
    display: block;
    margin-top: 345px;
    max-width: 1000px;
  }
`;

export const NumberOfBlock2 = styled(NumberOfBlock)`
  margin-top: 15px;

  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.03)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${BREAKPOINTS.S} {
    margin-top: -160px;
  }
`;

export const NumberOfBlock2Border = styled(NumberOfBlockBorder)`
  margin-top: 15px;

  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px transparent;

  ${BREAKPOINTS.S} {
    margin-top: -160px;
  }
`;

export const Title2 = styled(Title)`
  max-width: 330px;
  ${BREAKPOINTS.M} {
    max-width: 1000px;
  }
`;

export const Separator = styled.div`
  margin-bottom: 40px;
`;

export const ThinText = styled.span`
  color: ${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.regular};
  white-space: normal;
  opacity: 0.7;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  max-width: 90vw;
  ${BREAKPOINTS.M} {
    flex-direction: row;
  }
`;

export const WhatWeOffer = styled.div`
  max-width: 90vw;
  white-space: normal;
  font-family: ${({theme}) => theme.fonts.bold};
  font-size: 16px;
  opacity: 1;
  line-height: 26px;
  margin-right: 10px;
  ${BREAKPOINTS.XL} {
    max-width: 443px;
    margin-right: 40px;
  }
`;

export const WhatWeOfferTop = styled(WhatWeOffer)`
  ${BREAKPOINTS.M} {
    margin-bottom: 30px;
  }
`;

export const WhatWeOfferBottom = styled(WhatWeOffer)``;

export const WhatWeOfferBottom2 = styled(WhatWeOffer)`
  margin-bottom: 0px;
`;

export const WhyWe = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 920px;
  height: 70px;
  line-height: 24px;
`;

export const Benefits = styled.div`
  display: flex;
  align-items: flex-end;
  width: fit-content;
  margin: 80px 0;
`;

export const Benefits2 = styled(Benefits)``;

export const Benefits3 = styled(Benefits2)``;

export const TextAroundNum = styled.div`
  font-family: ${({theme}) => theme.fonts.regular};
  font-size: 16px;
  white-space: normal;
  width: 20px;
  line-height: 20px;
  word-wrap: normal;
`;

export const TextAroundNumRight = styled(TextAroundNum)`
  width: 85px;
  ${BREAKPOINTS.M} {
    width: 85px;
  }
`;

export const Vr360 = styled(TextAroundNum)`
  text-align: left;
  white-space: normal;
  width: 75px;
  margin-right: 5px;
  ${BREAKPOINTS.M} {
    text-align: right;
  }
`;

export const Number = styled.div`
  width: 110px;
  height: 80px;
  font-size: 96px;
  font-family: ${({theme}) => theme.fonts.regular};
  font-weight: 700;
  line-height: 80px;
  background-image: linear-gradient(180deg, #4e9fff 0%, #de11ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Seven = styled.div`
  font-family: ${({theme}) => theme.fonts.regular};
  font-size: 24px;
  font-weight: 700;
  background-image: linear-gradient(180deg, #4e9fff 0%, #de11ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
