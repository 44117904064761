import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';

export const GlobalGradientWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: ${({theme}) => theme.zIndex.low};
  max-height: 100vh;
`;

export const HorizontalGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90.43deg,
    rgba(0, 0, 0, 0.3) 11.42%,
    rgba(0, 0, 0, 0) 53.89%,
    rgba(0, 0, 0, 0.3) 84.47%
  );

  ${BREAKPOINTS.M} {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 1.04%,
      rgba(0, 0, 0, 0) 33.85%,
      rgba(0, 0, 0, 0) 63.02%,
      rgba(0, 0, 0, 0.0212766) 63.03%,
      rgba(0, 0, 0, 0.5) 87.5%,
      rgba(0, 0, 0, 0.92) 100%
    );
  }
`;

export const VerticalGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.35) 24.45%,
    rgba(0, 0, 0, 0) 49.91%,
    rgba(0, 0, 0, 0.35) 78.87%,
    rgba(0, 0, 0, 0.92) 99.74%
  );

  ${BREAKPOINTS.M} {
    background: linear-gradient(
      89.92deg,
      rgba(0, 0, 0, 0.9) 0.08%,
      rgba(0, 0, 0, 0) 33.88%,
      rgba(0, 0, 0, 0) 80.69%,
      #000000 99.93%
    );
  }
`;
