import styled from 'styled-components';

import {BREAKPOINTS} from 'styles/breakpoints';

interface IProps {
  view: boolean;
}

export const ContactsWrapper = styled.div<IProps>`
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: black;
  z-index: 2000;
  color: ${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.regular};
  font-size: 16px;
  transition: all 0.5s;

  ${BREAKPOINTS.XS} {
    width: 100vw;
    transform: ${(IProps) =>
      IProps.view ? 'translateY(0)' : 'translateY(100%)'};
  }

  ${BREAKPOINTS.M} {
    width: 738px;
    transform: ${(IProps) =>
      IProps.view ? 'translateX(0)' : 'translateX(100%)'};
  }

  ${BREAKPOINTS.XL} {
    width: 40vw;
    font-size: 14px;
  }
  ${BREAKPOINTS.XXL} {
    width: 738px;
    font-size: 16px;
  }
`;

export const Cross = styled.div`
  svg {
    width: 24px;
    height: 24px;
    opacity: 0.7;
    transition: 0.5s;
    :hover {
      opacity: 1;
      transition: 0.5s;
    }
    ${BREAKPOINTS.M} {
      width: 40px;
      height: 40px;
    }
  }
  z-index: 1500;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 18px;
  top: 25px;
  cursor: pointer;
  opacity: 1;
  transition: 0.5s;
  background-color: black;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  ${BREAKPOINTS.L} {
    position: fixed;
    right: 40px;
    top: 45px;
  }
`;

export const ContactsList = styled.div`
  max-width: 320px;
  display: flex;
  flex-direction: column;
  margin-top: 125px;
  position: relative;
  height: 70vh;
  p {
    color: ${({theme}) => theme.colors.white};
    font-family: ${({theme}) => theme.fonts.regular};
    margin-bottom: 40px;
  }

  ${BREAKPOINTS.XS} {
    margin-left: 31px;
  }

  ${BREAKPOINTS.S} {
    margin-left: 116px;
  }

  ${BREAKPOINTS.M} {
    margin-top: 100px;
    height: 80vh;
  }
  ${BREAKPOINTS.XXL} {
    margin-top: 200px;
    height: 75vh;
  }
`;

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const ContactItemIcon = styled.div`
  svg {
    width: 18px;
    height: 18px;
    margin-right: 25px;
    margin-top: 5px;
  }
`;

export const ContactItemText = styled.div`
  color: ${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.regular};
  line-height: 25px;
  opacity: 0.6;
  white-space: normal;
`;

export const WeAreInSocial = styled.p`
  color: ${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.regular};
  margin: 76px 0 40px 0;
`;

export const SocialIcons = styled.div`
  display: flex;
`;

export const SocialItems = styled.div`
  svg {
    width: 18px;
    height: 18px;
    margin: 0 24px 0 0;
    cursor: pointer;
    ${BREAKPOINTS.XL} {
      width: 15px;
      height: 15px;
    }
    ${BREAKPOINTS.XXL} {
      width: 18px;
      height: 18px;
    }
  }
`;

export const Copyright = styled.div`
  color: ${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.regular};
  opacity: 0.6;
  bottom: 0;
  position: absolute;
`;
