import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';

export const GreetingsWrapper = styled.div<{loading: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  padding: 0 30px;
  color: ${({theme}) => theme.colors.white};
  background-color: ${({theme}) => theme.colors.black};
  z-index: ${({theme}) => theme.zIndex.high};
`;

export const GreetingsText = styled.span`
  font-size: 40px;
  font-family: ${({theme}) => theme.fonts.bold};
  line-height: 111.5%;
  white-space: break-spaces;

  ${BREAKPOINTS.L} {
    display: inline-block;
    max-width: 930px;
    margin: 0 auto;
    font-size: 60px;
    line-height: 117%;
    text-align: center;
  }
`;

export const ProgressWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 110px;
  transform: translateX(-50%);
`;

export const GradientText = styled.span`
  background: linear-gradient(180deg, #4e9fff 0%, #de11ff 100%);
  font-family: inherit;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
