import React, {useRef, useEffect} from 'react';
import {useMode} from 'context/mode';
import {useTranslation} from 'react-i18next';
import {
  ContactsWrapper,
  Cross,
  ContactsList,
  ContactItem,
  SocialIcons,
  WeAreInSocial,
  SocialItems,
  ContactItemIcon,
  ContactItemText,
  Copyright,
} from './styled';

import {ReactComponent as Map} from 'assets/icons/map.svg';
import {ReactComponent as Email} from 'assets/icons/email-icon.svg';
import {ReactComponent as Phone} from 'assets/icons/phone-icon.svg';
import {ReactComponent as Whatsapp} from 'assets/icons/whatsapp.svg';
import {ReactComponent as LinkedIn} from 'assets/icons/linked-in.svg';
import {ReactComponent as Instagram} from 'assets/icons/inst.svg';
import {ReactComponent as Facebook} from 'assets/icons/facebook.svg';
import {ReactComponent as Twitter} from 'assets/icons/twitter.svg';
import {ReactComponent as CloseIcon} from 'assets/icons/close-icon.svg';

export const Contacts = (): JSX.Element | null => {
  const {t} = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const context = useMode();
  const {toggleContacts, viewContacts} = context;

  useEffect(() => {
    const modal = (e: MouseEvent): void => {
      e.stopPropagation();
      const {target} = e;
      if (
        viewContacts &&
        ref.current !== null &&
        !ref.current.contains(target as Node)
      ) {
        toggleContacts(false);
      }
    };
    window.addEventListener('mousedown', modal);

    return (): void => {
      window.removeEventListener('mousedown', modal);
    };
  }, [viewContacts, ref.current]);

  const toggle = (e: React.MouseEvent): void => {
    e.stopPropagation();
    toggleContacts(false);
  };

  return (
    <ContactsWrapper ref={ref} view={viewContacts}>
      <Cross>
        <CloseIcon onClick={toggle} />
      </Cross>
      <ContactsList>
        <p>{t('text.contactUs')}</p>
        <ContactItem>
          <ContactItemIcon>
            <Map />
          </ContactItemIcon>
          <ContactItemText>{t('text.directAddress')}</ContactItemText>
        </ContactItem>
        <ContactItem>
          <ContactItemIcon>
            <Email />
          </ContactItemIcon>
          <a href="mailto:office@twins.com.ua">
            <ContactItemText>office@twins.com.ua</ContactItemText>
          </a>
        </ContactItem>
        <ContactItem>
          <ContactItemIcon>
            <Phone />
          </ContactItemIcon>
          <ContactItemText>+380 95 322 9573</ContactItemText>
        </ContactItem>
        <WeAreInSocial>{t('text.weAreOnSocialMedia')}</WeAreInSocial>
        <SocialIcons>
          <SocialItems>
            <a
              href="https://wa.me/380953229573"
              target="blank"
              rel="noreferrer"
            >
              <Whatsapp />
            </a>
          </SocialItems>
          <SocialItems>
            <a
              href="https://www.linkedin.com/company/twins-office/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedIn />
            </a>
          </SocialItems>
          <SocialItems>
            <a
              href="https://instagram.com/twins.com.ua?igshid=YmMyMTA2M2Y="
              target="blank"
              rel="noreferrer"
            >
              {' '}
              <Instagram />
            </a>
          </SocialItems>
          <SocialItems>
            <a
              href="https://www.facebook.com/twins.architecture"
              target="blank"
              rel="noreferrer"
            >
              <Facebook />
            </a>
          </SocialItems>
          <SocialItems>
            <a
              href="https://twitter.com/twins_office"
              target="blank"
              rel="noreferrer"
            >
              <Twitter />
            </a>
          </SocialItems>
        </SocialIcons>
        <Copyright>© 2023 Twins Architecture Office LLC</Copyright>
      </ContactsList>
    </ContactsWrapper>
  );
};
