import styled, {keyframes} from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';

interface Animation {
  count: number;
}

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 209px;
`;

export const ProgressStatusWrapper = styled.div`
  position: relative;
  height: 11px;
  width: 100%;
  margin-bottom: 16px;
`;

const ProgressStatus = styled.div`
  position: absolute;
  line-height: 1;
  top: 0;
  transform: translateX(-50%);
  font-size: 16px;
  font-family: ${({theme}) => theme.fonts.regular};
`;

let animationCounter = '';

for (let i = 1; i <= 100; i++) {
  animationCounter += `${i}% {
    left: ${i}%;
  }`;
}

let animationString = '';

for (let i = 1; i <= 100; i++) {
  animationString += `${i}% {
    width: ${i}%;
  }`;
}

export const firstPercentageAnimation = keyframes`

 ${animationCounter}

`;

export const MobileCounter = styled(ProgressStatus)`
  display: inline-block;
  animation: ${firstPercentageAnimation} 6000ms linear both;

  ${BREAKPOINTS.L} {
    display: none;
  }
`;

export const DesktopCounter = styled.div`
  display: none;
  position: absolute;
  line-height: 1;
  top: 0;
  transform: translateX(-50%);
  font-size: 16px;
  font-family: ${({theme}) => theme.fonts.regular};

  ${BREAKPOINTS.L} {
    display: inline-block;

    animation: ${firstPercentageAnimation} 8000ms linear both;
  }
`;

export const ProgressContent = styled.div`
  background: ${({theme}) => theme.colors.grey1};
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.38);
  border-radius: 30px;
  width: 100%;
  height: 6px;
`;

const mobileWidthAnimation = keyframes`

  ${animationString}
`;

const desktopWidthAnimation = keyframes`

  ${animationString}
`;

export const ProgressLine = styled.div<Animation>`
  height: 100%;
  background-color: ${({theme}) => theme.colors.white};
  border-radius: 30px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
  animation: ${mobileWidthAnimation} 6000ms linear both;

  ${BREAKPOINTS.L} {
    animation: ${desktopWidthAnimation} 8000ms linear both;
  }
`;
