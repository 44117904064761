import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {LeftNumbers} from './left-numbers';
import {RightNumbers} from './right-numbers';
import {CenterNumbers} from './center-numbers';

import {
  Separator,
  Title2,
  SingleBlock2,
  NumberOfBlock2,
  NumberOfBlockBorder2,
  WhatWeOffer,
  WhatWeOfferBottom2,
  TextWrapper,
  ThinText,
} from './styled';

import {ReactComponent as Line} from 'assets/icons/separation_line.svg';

export const Section2Mobile = (): JSX.Element | null => {
  
  const {t} = useTranslation();

  return (
    <>
      <SingleBlock2 id="whatweoffer">
        <NumberOfBlock2>02</NumberOfBlock2>
        <NumberOfBlockBorder2>02</NumberOfBlockBorder2>
        <Title2>{t('text.whatDoWeOffer')}</Title2>
        <Separator>
          <Line />
        </Separator>
        <TextWrapper>
          <WhatWeOffer>
            <WhatWeOffer>
              <Trans
                t={t}
                i18nKey="text.whatWeOfferLeftTop"
                components={[<ThinText key={0} />]}
              />
            </WhatWeOffer>
            <LeftNumbers />
            <WhatWeOffer>
              <Trans
                t={t}
                i18nKey="text.whatDoWeOfferLeft"
                components={[<ThinText key={0} />]}
              />
            </WhatWeOffer>
            <CenterNumbers />
          </WhatWeOffer>
          <WhatWeOffer>
            <WhatWeOffer>
              <Trans
                t={t}
                i18nKey="text.whatWeOfferRightTop"
                components={[<ThinText key={0} />]}
              />
            </WhatWeOffer>
            <RightNumbers />
            <WhatWeOfferBottom2>
              <Trans
                t={t}
                i18nKey="text.whatDoWeOfferRight"
                components={[<ThinText key={0} />]}
              />
            </WhatWeOfferBottom2>
          </WhatWeOffer>
        </TextWrapper>
      </SingleBlock2>
    </>
  );
};
