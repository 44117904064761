import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';

export const NavigationsWrapper = styled.footer`
  display: block;
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translate(-50%);
  z-index: ${({theme}) => theme.zIndex.medium};

  ${BREAKPOINTS.XS} {
    display: block;
  }

  ${BREAKPOINTS.L} {
    display: none;
  }
`;


export const Slide = styled.div`

`