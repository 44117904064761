import React from 'react';
import {
  GlobalGradientWrapper,
  VerticalGradient,
  HorizontalGradient,
} from './styled';

export const GlobalGradient = (): JSX.Element => {
  return (
    <GlobalGradientWrapper>
      <HorizontalGradient />
      <VerticalGradient />
    </GlobalGradientWrapper>
  );
};
