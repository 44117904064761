import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';

import {
  Benefits,
  Number,
  TextAroundNum,
  TextAroundNumRight,
} from './styled';

export const LeftNumbers = (): JSX.Element | null => {
  const {t} = useTranslation();

  const [count, setCount] = useState<number>(0);

  const {ref, inView} = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (inView) {
        setCount((count) => (count < 90 ? count + 1 : count));
      } else {
        setCount(0);
      }
    }, 25);
    return () => clearInterval(interval);
  }, [inView]);

  return (
    <div ref={ref}>
      <Benefits>
        <TextAroundNum>{t('text.by')}</TextAroundNum>
        <Number>{count}</Number>
        <TextAroundNumRight>{t('text.accelerated')}</TextAroundNumRight>
      </Benefits>
    </div>
  );
};
