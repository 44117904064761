import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';

interface IProps {
  active: number;
}

export const Menu = styled.div`
  position: fixed;
  width: 267px;
  height: 225px;
  margin-left: 10%;
  display: none;
  ul {
    list-style-type: none;
  }
  li {
    margin-bottom: 40px;
    width: 154px;
    white-space: normal;
    cursor: pointer;
    font-family: ${({theme}) => theme.fonts.regular};
    transition: 0.5s;
    a {
      color: ${({theme}) => theme.colors.white};
      font-family: ${({theme}) => theme.fonts.regular};
      ${BREAKPOINTS.XL} {
        font-size: 14px;
      }
      ${BREAKPOINTS.XXL} {
        font-size: 16px;
      }

    }
  }
  ${BREAKPOINTS.L} {
    display: block;
    margin-left: 0%;
  }
`;

export const TabWrapper = styled.div`
  position: relative;
`;

export const Marker = styled.div<IProps>`
  position: absolute;
  transition: transform 0.3s;
  transform: ${(IProps) => {
    if (IProps.active === 1) {
      return 'translateY(0px)';
    } else if (IProps.active === 2) {
      return 'translateY(65px)';
    } else if (IProps.active === 3) {
      return 'translateY(130px)';
    } else if (IProps.active === 4) {
      return 'translateY(195px)';
    } else {
      return null;
    }
  }};
`;
