import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';


export const Benefits = styled.div`
display: flex;
align-items: flex-end;
width: fit-content;
margin: 80px 0;
`;



export const TextAroundNum = styled.div`
font-family: ${({theme}) => theme.fonts.regular};
font-size: 16px;
white-space: normal;
width: 20px;
line-height: 20px;
word-wrap: normal;
`;

export const TextAroundNumRight = styled(TextAroundNum)`
width: 85px;
${BREAKPOINTS.M} {
  width: 85px;
}
`;


export const Vr360 = styled(TextAroundNum)`
  text-align: left;
  white-space: normal;
  width: 75px;
  margin-right: 5px;
  ${BREAKPOINTS.M} {
    text-align: right;
  }
`;

export const Number = styled.div`
  width: 110px;
  height: 80px;
  font-size: 96px;
  font-family: ${({theme}) => theme.fonts.regular};
  font-weight: 700;
  line-height: 80px;
  background-image: linear-gradient(180deg, #4e9fff 0%, #de11ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Seven = styled.div`
  font-family: ${({theme}) => theme.fonts.regular};
  font-size: 24px;
  font-weight: 700;
  background-image: linear-gradient(180deg, #4e9fff 0%, #de11ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
