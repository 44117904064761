import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';
import {ReactComponent as Right} from 'assets/icons/right.svg';
import {ReactComponent as Left} from 'assets/icons/left.svg';

interface Props {
  visible: boolean;
}

export const SlidesWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 1370px;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: ${({theme}) => theme.zIndex.high};
  
  ${BREAKPOINTS.L} {
   width: 75vw;
  }
`;

export const RightArrow = styled.div<Props>`
  display: inline-block;
  pointer-events: all;
  cursor: pointer;
  right: 31px;
  opacity: ${(Props) => (Props.visible ? '1' : '0')};
  pointer-events: ${(Props) => (Props.visible ? 'all' : 'none')};
  transition: opacity 0.5s;
  padding: 6%;
  float: right;

  ${BREAKPOINTS.L} {
    padding: 2%;
  }
`;

export const RightArrowSvg = styled(Right)`
  width: 18px;
  height: 36px;

  ${BREAKPOINTS.L} {
    width: 24px;
    height: 47.17px;
  }
`;

export const LeftArrow = styled.div<Props>`
  display: inline-block;
  pointer-events: all;
  cursor: pointer;
  left: 31px;
  opacity: ${(Props) => (Props.visible ? '1' : '0')};
  pointer-events: ${(Props) => (Props.visible ? 'all' : 'none')};
  transition: opacity 0.5s;
  padding: 6%;

  ${BREAKPOINTS.L} {
    padding: 2%;
  }
`;

export const LeftArrowSvg = styled(Left)`
  width: 18px;
  height: 36px;

  ${BREAKPOINTS.L} {
    width: 24px;
    height: 47.17px;
  }
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 10;

  ${BREAKPOINTS.L} {
    transform: translate(-50%, -50%);
  }
`;
