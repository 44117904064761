import React from 'react';
import {useMode} from 'context/mode';
import {Section2Mobile} from './section2-mobile';
import {Section1} from './section1';
import {Section2} from './section2';
import {Section3} from './section3';
import {Section4} from './section4';
import {Tabs} from './tabs';

import {
  WrapperForScroll,
  AboutUsWrapper,
  RightBlocksWrapper,
  WrapperForScrollAndCopy,
  Copyright,
  ScrollUp,
  WrapperForCross,
  TabsWrapper,
} from './styled';
import {Cross} from 'components/contacts/styled';
import {ReactComponent as CloseIcon} from 'assets/icons/close-icon.svg';
import {ReactComponent as ArrowUp} from 'assets/icons/arrowup.svg';

export const AboutUs = (): JSX.Element | null => {
  const context = useMode();
  const {toggleAboutUs, viewAboutUs} = context;

  const Toggle = (e: React.MouseEvent): void => {
    e.stopPropagation();
    toggleAboutUs();
  };

  return (
    <>
      <WrapperForScroll view={viewAboutUs}>
        <AboutUsWrapper>
          <a href="#who-are-we">
            <WrapperForCross onClick={Toggle}>
              <Cross>
                <CloseIcon />
              </Cross>
            </WrapperForCross>
          </a>
          <TabsWrapper>
            <Tabs />
          </TabsWrapper>
          <RightBlocksWrapper>
            <div style={{ marginBottom: '50px' }} id="who-are-we"></div>
            <Section1 />
            <Section2 />
            <Section2Mobile />
            <Section3 />
            <Section4 />
            <WrapperForScrollAndCopy>
              <Copyright>© 2023 Twins Architecture Office LLC</Copyright>
              <a href="#who-are-we">
                <ScrollUp>
                  <ArrowUp />
                </ScrollUp>
              </a>
            </WrapperForScrollAndCopy>
          </RightBlocksWrapper>
        </AboutUsWrapper>
      </WrapperForScroll>
    </>
  );
};
