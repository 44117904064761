import React, {useEffect, useMemo} from 'react';
import {useMode} from 'context/mode';

import {
  SlideContentWrapper,
  SocialWrapper,
  SlideInfo,
  SlideTitle,
  SlideMeta,
  SlideSubTitle,
  DesktopSlides,
  OurProjects,
  DesktopSlide,
  CurrentSlide,
  SlidesInfo,
  SlidesInfoTop,
  HintBlock,
  GoBack,
  DesktopHintBlock,
  NextSlideInfo,
  NextSlide,
  NextSlideMeta,
  NextSlideSubTitle,
  NextSlideImage,
  Hint,
  AllSlides,
  CounterWrapper,
  GoBackTextDesktop,
  GoBackTextMobile,
} from './styled';

import {useAppSelector} from 'store/hooks';

import {ReactComponent as Phone} from 'assets/icons/phone-hint.svg';
import {ReactComponent as GoBackHand} from 'assets/icons/go-back-hand.svg';
import {ReactComponent as Mouse} from 'assets/icons/mouse-icon.svg';
import {useTranslation} from 'react-i18next';

import {ReactComponent as LinkedIn} from 'assets/icons/linked-in.svg';
import {ReactComponent as Instagram} from 'assets/icons/inst.svg';
import {ReactComponent as WhatsApp} from 'assets/icons/whatsapp.svg';

import bmwGarage from 'assets/images/bmw-garage.jpg';
import countryClub from 'assets/images/lords-valley-country-club.jpg';
import apartmentKharkiv from 'assets/images/apartment-on-the-klochkivska-street-kharkiv.jpg';
import fitnessClub from 'assets/images/fitness-club-in-minimalism-style.jpg';
import monument from 'assets/images/apartment-in-an-architectural-monument.jpg';
import kyivCenter from 'assets/images/apartment-in-the-center-of-kyiv.jpg';

const prepareNumber = (deg: number): string => `0${deg}`.slice(-2);

export const SlideContent = (): JSX.Element | null => {
  const context = useMode();
  const {
    visible,
    changeVisible,
    toggleSlideContent,
    changeVisibleMobileHint,
    visibleMobileHint,
    toggleNextSlide,
  } = context;

  useEffect(() => {
    toggleSlideContent(true);
  }, [toggleSlideContent]);

  useEffect(() => {
    const setInvisible = setTimeout(() => {
      changeVisibleMobileHint(false);
    }, 9000);
    return () => clearInterval(setInvisible);
  }, []);

  const next = (): void => {
    toggleNextSlide(true);
    setTimeout(() => {
      toggleNextSlide(false);
    }, 500);
  };

  const slideContent = useAppSelector((state: any) => state.slide);

  const {
    title,
    subTitle,
    slideNumber,
    nextSlide,
    nextSlideId,
    nextSlideTitle,
    slidesQuantity,
  } = slideContent.value;

  const changeVisibleContent = (): void => {
    changeVisible();
  };

  const {t} = useTranslation();
  const slide = useMemo(() => prepareNumber(slideNumber), [title]);
  const quantity = useMemo(() => prepareNumber(slidesQuantity), [title]);

  return (
    <>
      <SlideContentWrapper>
        <SocialWrapper visible={visible}>
          <a href="https://wa.me/380953229573" target="blank" rel="noreferrer">
            <WhatsApp />
          </a>
          <a
            href="https://www.linkedin.com/company/twins-office/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn />
          </a>
          <a
            href="https://instagram.com/twins.com.ua?igshid=YmMyMTA2M2Y="
            target="blank"
            rel="noreferrer"
          >
            <Instagram />
          </a>
        </SocialWrapper>
        <SlidesInfoTop visible={visible}>
          <CurrentSlide>{slide}</CurrentSlide> / {quantity}
        </SlidesInfoTop>
        <NextSlideInfo visible={visible} onClick={next}>
          <NextSlideMeta>
            <NextSlide>{prepareNumber(nextSlide)}</NextSlide>
            <NextSlideSubTitle visible={visible}>
              {nextSlideTitle}
            </NextSlideSubTitle>
          </NextSlideMeta>
          <NextSlideImage
            src={bmwGarage}
            style={{display: nextSlideId === 'bmw-garage' ? '' : 'none'}}
          />
          <NextSlideImage
            src={countryClub}
            style={{
              display:
                nextSlideId === 'lords-valley-country-club' ? '' : 'none',
            }}
          />
          <NextSlideImage
            src={apartmentKharkiv}
            style={{
              display:
                nextSlideId === 'apartment-on-the-klochkivska-street-kharkiv'
                  ? ''
                  : 'none',
            }}
          />
          <NextSlideImage
            src={fitnessClub}
            style={{
              display:
                nextSlideId === 'fitness-club-in-minimalism-style'
                  ? ''
                  : 'none',
            }}
          />
          <NextSlideImage
            src={monument}
            style={{
              display:
                nextSlideId === 'apartment-in-an-architectural-monument'
                  ? ''
                  : 'none',
            }}
          />
          <NextSlideImage
            src={kyivCenter}
            style={{
              display:
                nextSlideId === 'apartment-in-the-center-of-kyiv' ? '' : 'none',
            }}
          />
        </NextSlideInfo>
        <HintBlock visible={visibleMobileHint}>
          <Phone />
          <Hint>{t('hints.mobile.activate')}</Hint>
        </HintBlock>
        <GoBack onClick={changeVisibleContent} visible={visible}>
          <GoBackHand />
          <GoBackTextMobile>{t('hints.mobile.deactivate')}</GoBackTextMobile>
          <GoBackTextDesktop>{t('hints.desktop.deactivate')}</GoBackTextDesktop>
        </GoBack>
        <DesktopHintBlock onClick={changeVisibleContent} visible={visible}>
          <Mouse />
          <Hint>{t('hints.desktop.activate')}</Hint>
        </DesktopHintBlock>
        <DesktopSlides>
          <OurProjects visible={visible}>{t('text.ourProjects')}</OurProjects>
          <CounterWrapper visible={visible}>
            <DesktopSlide>{slide}</DesktopSlide>
            <AllSlides>/ {quantity}</AllSlides>
          </CounterWrapper>
        </DesktopSlides>
        <SlideInfo>
          <SlidesInfo visible={visible}>
            <CurrentSlide>{slide}</CurrentSlide> / {('0' + String(slidesQuantity)).slice(-2)}
          </SlidesInfo>
          <SlideMeta visible={visible}>
            <SlideTitle visible={visible}>{title}</SlideTitle>
            <SlideSubTitle visible={visible}>{subTitle}</SlideSubTitle>
          </SlideMeta>
        </SlideInfo>
      </SlideContentWrapper>
    </>
  );
};
