import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';


import {
  TextWrapper,
  Column,
  Column2,
  Column3,
  CooperationText,
  ColabText,
  ColabTitle,
  ColabNum,
  NumWithDots,
  WrapperForTitle,
  ThinText,
} from './styled';

import {ReactComponent as Dot} from 'assets/icons/dot.svg';
import {ReactComponent as ShortLine} from 'assets/icons/seperation_line_short.svg';

export const ColumnWrapper = (): JSX.Element | null => {

  const {t} = useTranslation();
  const [animation, setAnimation] = useState<boolean>(false);

  const {ref, inView} = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setAnimation(true);
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <TextWrapper>
        <Column view={animation}>
          <NumWithDots>
            <ColabNum>01</ColabNum>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
          </NumWithDots>
          <CooperationText>
            <WrapperForTitle>
              <ColabTitle>{t('text.design')}</ColabTitle>
              <div>
                <ShortLine />
              </div>
            </WrapperForTitle>
            <ColabText>
              <Trans
                t={t}
                i18nKey="text.designtext"
                components={[<ThinText key={0} />]}
              />
            </ColabText>
          </CooperationText>
        </Column>
        <Column2 view={animation}>
          <NumWithDots>
            <ColabNum>02</ColabNum>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
            <div>
              <Dot />
            </div>
          </NumWithDots>
          <CooperationText>
            <WrapperForTitle>
              <ColabTitle>{t('text.determine')}</ColabTitle>
              <div>
                <ShortLine />
              </div>
            </WrapperForTitle>
            <ColabText>
              <Trans
                t={t}
                i18nKey="text.determinetext"
                components={[<ThinText key={0} />]}
              />
            </ColabText>
          </CooperationText>
        </Column2>
        <Column3 view={animation}>
          <NumWithDots>
            <ColabNum>03</ColabNum>
          </NumWithDots>
          <CooperationText>
            <WrapperForTitle>
              <ColabTitle>{t('text.create')}</ColabTitle>
              <div>
                <ShortLine />
              </div>
            </WrapperForTitle>
            <ColabText>
              <Trans
                t={t}
                i18nKey="text.createtext"
                components={[<ThinText key={0} />]}
              />
            </ColabText>
          </CooperationText>
        </Column3>
      </TextWrapper>
    </div>
  );
};
