import React, {useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';
import {useMode} from 'context/mode';
import {WhyWeWrapper} from './why-we';

import {
  Separator,
  Title2,
  SingleBlock2,
  NumberOfBlock2,
  NumberOfBlock2Border,
  WhatWeOffer,
  WhatWeOfferTop,
  WhatWeOfferBottom,
  WhatWeOfferBottom2,
  TextWrapper,
  ThinText,
} from './styled';

import {ReactComponent as Line} from 'assets/icons/separation_line.svg';

export const Section2 = (): JSX.Element | null => {
  const {t} = useTranslation();
  const context = useMode();
  const {toggleActiveTab2} = context;

  const {ref, inView} = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    toggleActiveTab2(inView);
  }, [inView]);

  return (
    <div ref={ref}>
      <SingleBlock2>
        <NumberOfBlock2 id="whatweoffer">02</NumberOfBlock2>
        <NumberOfBlock2Border>02</NumberOfBlock2Border>
        <Title2>{t('text.whatDoWeOffer')}</Title2>
        <Separator>
          <Line />
        </Separator>
        <TextWrapper>
          <WhatWeOffer>
            <WhatWeOfferTop>
              <Trans
                t={t}
                i18nKey="text.whatWeOfferLeftTop"
                components={[<ThinText key={0} />]}
              />
            </WhatWeOfferTop>
            <WhatWeOfferBottom>
              <Trans
                t={t}
                i18nKey="text.whatDoWeOfferLeft"
                components={[<ThinText key={0} />]}
              />
            </WhatWeOfferBottom>
          </WhatWeOffer>
          <WhatWeOffer>
            <WhatWeOfferTop>
              <Trans
                t={t}
                i18nKey="text.whatWeOfferRightTop"
                components={[<ThinText key={0} />]}
              />
            </WhatWeOfferTop>
            <WhatWeOfferBottom2>
              <Trans
                t={t}
                i18nKey="text.whatDoWeOfferRight"
                components={[<ThinText key={0} />]}
              />
            </WhatWeOfferBottom2>
          </WhatWeOffer>
        </TextWrapper>
        <WhyWeWrapper />
      </SingleBlock2>
    </div>
  );
};
