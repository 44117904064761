import styled, {keyframes} from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';

interface Props {
  visible: boolean;
}

export const breatheAnimation = keyframes`
from {
  transform: scale(1);
}
to {
  transform: scale(1.5);
}
`;

const fadeInOut = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const SlideContentWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 1370px;
  padding: 0 31px;
  transform: translate(-50%, -50%);
  height: 60vh;
  z-index: 100;
  ${BREAKPOINTS.L} {
    height: 70vh;
    width: 75vw;
  }
`;

export const SocialWrapper = styled.div<Props>`
  display: none;

  ${BREAKPOINTS.L} {
    display: flex;
    flex-direction: column;
    left: 0;
    top: 0;
    width: 20px;
    pointer-events: all;
    svg {
      opacity: ${(Props) => (Props.visible ? '1' : '0')};
      margin-top: 20px;
      width: 18px;
      height: 18px;
      transition: all 0.5s;
      ${BREAKPOINTS.XL} {
        width: 15px;
        height: 15px;
      }
      ${BREAKPOINTS.XXL} {
        width: 18px;
        height: 18px;
      }
    }
  }
`;

export const HintBlock = styled.div<Props>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  font-size: 14px;
  text-align: center;
  font-family: ${({theme}) => theme.fonts.regular};
  color: ${({theme}) => theme.colors.white};
  transform: translate(-50%, -50%);
  cursor: pointer;
  pointer-events: visible;
  opacity: ${(Props) => (Props.visible ? '1' : '0')};
  pointer-events: ${(Props) => (Props.visible ? 'visible' : 'none')};
  transition: all 0.5s;

  svg {
    width: 27px;
    height: 32px;
    margin-bottom: 16px;
    animation: ${breatheAnimation} 0.5s linear alternate both infinite;
  }

  ${BREAKPOINTS.XL} {
    display: none;
  }
`;

export const GoBack = styled(HintBlock)<Props>`
  top: 80%;
  opacity: ${(Props) => (Props.visible ? '0' : '1')};
  pointer-events: ${(Props) => (Props.visible ? 'none' : 'visible')};
  display: none;
  padding: 2%;

  ${BREAKPOINTS.XL} {
    display: flex;
    top: 93%;
  }
`;

export const Hint = styled.span`
  display: inline;
  white-space: break-spaces;
  width: 120px;

  ${BREAKPOINTS.XL} {
    width: 164px;
  }
`;

export const GoBackTextDesktop = styled.span`
  display: none;
  white-space: break-spaces;
  width: 164px;
  ${BREAKPOINTS.XL} {
    display: inline;
  }
`;
export const GoBackTextMobile = styled.span`
  display: inline;
  white-space: break-spaces;
  width: 120px;
  ${BREAKPOINTS.XL} {
    display: none;
  }
`;

export const DesktopHintBlock = styled(HintBlock)<Props>`
  display: none;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  padding: 2%;

  svg {
    width: 34px;
    height: 32px;
    margin-bottom: 8px;
    animation: ${breatheAnimation} 0.5s linear alternate both infinite;
  }

  ${BREAKPOINTS.XL} {
    display: flex;
    align-items: center;
    transition: all 0.5s;
    pointer-events: ${(Props) => (Props.visible ? 'visible' : 'none')};
    opacity: ${(Props) => (Props.visible ? '1' : '0')};
  }
`;

export const NextSlideInfo = styled.div<Props>`
  position: absolute;
  top: -10%;
  left: 35%;
  display: none;
  color: ${({theme}) => theme.colors.white};
  transition: all 0.5s;
  opacity: ${(Props) => (Props.visible ? '1' : '0')};
  pointer-events: ${(Props) => (Props.visible ? 'visible' : 'none')};
  /* animation: ${fadeInOut} 2s both; */
  ${BREAKPOINTS.M} {
    right: 0;
    left: unset;
  }

  ${BREAKPOINTS.L} {
    display: flex;
    top: unset;
    right: 0;
    bottom: 0;
    margin-right: 31px;
  }
`;

export const NextSlideImage = styled.img`
  width: 229.05px;
  height: 113px;
  cursor: pointer;

  ${BREAKPOINTS.L} {
    width: 220px;
    height: fit-content;
  }
  ${BREAKPOINTS.XXL} {
    width: 300px;
    height: 148px;
  }
`;

export const NextSlideMeta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 113px;
  line-height: 1.5;
  margin-right: 21px;

  ${BREAKPOINTS.L} {
    height: 120px;
    line-height: 1;
  }
  ${BREAKPOINTS.XXL} {
    height: 148px;
    line-height: 1.3;
  }
`;

export const SlideInfo = styled.div`
  position: absolute;
  bottom: -15px;
  left: 31px;
  ${BREAKPOINTS.XXL} {
    bottom: -15px;
  }
`;

export const SlideMeta = styled.div<Props>`
  display: flex;
  flex-direction: column;
  line-height: 30px;
  transition: all 0.5s;
  transform: ${(Props) => (Props.visible ? '0' : 'translateY(70px)')};
  ${BREAKPOINTS.XL} {
    flex-direction: column-reverse;
    transform: ${(Props) => (Props.visible ? '0' : 'translateY(0)')};
  }
`;

export const SlideTitle = styled.div<Props>`
  font-size: 25px;
  font-family: ${({theme}) => theme.fonts.medium};
  color: ${({theme}) => theme.colors.white};
  white-space: break-spaces;
  max-width: 350px;
  /* animation: ${fadeInOut} 2s both; */

  ${BREAKPOINTS.XL} {
    font-size: 30px;
    margin-bottom: 0;
    max-width: 500px;
    line-height: 50px;
    transition: all 0.5s;
    transform: ${(Props) =>
      Props.visible ? 'scale(1)' : ' scale(0.8) translate(-13%)'};
  }
  ${BREAKPOINTS.XXL} {
    font-size: 64px;
    line-height: 75px;
  }
`;

export const SlideSubTitle = styled.div<Props>`
  font-size: 14px;
  font-family: ${({theme}) => theme.fonts.regular};
  color: ${({theme}) => theme.colors.white};
  transition: all 0.5s;
  transform: ${(Props) => (Props.visible ? '0' : 'translateY(10px)')};
`;

export const NextSlideSubTitle = styled(SlideSubTitle)<Props>`
  white-space: break-spaces;
  text-align: end;
  width: 132px;
  /* animation: ${fadeInOut} 2s both; */

  ${BREAKPOINTS.XL} {
    font-size: 14px;
    line-height: 20px;
    width: 119px;
  }
  ${BREAKPOINTS.XXL} {
    font-size: 16px;
    width: 119px;
  }
`;

export const SlidesInfo = styled.div<Props>`
  font-size: 16px;
  font-family: ${({theme}) => theme.fonts.light};
  color: ${({theme}) => theme.colors.white};
  opacity: ${(Props) => (Props.visible ? '1' : '0')};
  transition: opacity 0.5s;
  ${BREAKPOINTS.L} {
    display: none;
  }
`;

export const SlidesInfoTop = styled(SlidesInfo)<Props>`
  position: absolute;
  right: 10%;
  top: -10%;
  opacity: ${(Props) => (Props.visible ? '0' : '1')};
`;

export const DesktopSlides = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 36px;
  font-family: ${({theme}) => theme.fonts.light};
  color: ${({theme}) => theme.colors.white};
  pointer-events: none;
  /* animation: ${fadeInOut} 2s both; */

  ${BREAKPOINTS.L} {
    display: flex;
    align-items: flex-end;
    height: 90px;
    margin-right: 31px;
  }
`;

export const OurProjects = styled.div<Props>`
  font-size: 11px;
  width: 50px;
  margin-right: 8px;
  white-space: break-spaces;
  text-align: end;
  margin-top: 46px;
  padding-bottom: 6px;
  font-family: ${({theme}) => theme.fonts.regular};
  transform: ${(Props) => (Props.visible ? 'none' : 'translate(16px, -12px)')};
  transition: all 0.5s;
  ${BREAKPOINTS.XXL} {
    font-size: 16px;
    width: 73px;
    margin-right: 16px;
    padding-bottom: 10px;
    transform: ${(Props) =>
      Props.visible ? 'none' : 'translate(45px, -14px)'};
  }
`;

export const CounterWrapper = styled.div<Props>`
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  height: 116px;
  transform: ${(Props) => (Props.visible ? 'scale(1)' : 'scale(0.7)')};
  transition: all 0.5s;
  ${BREAKPOINTS.XXL} {
    transform: ${(Props) => (Props.visible ? 'scale(1)' : 'scale(0.6)')};
  }
`;

export const DesktopSlide = styled.div`
  font-size: 50px;
  line-height: 55px;
  font-family: ${({theme}) => theme.fonts.bold};
  transition: all 0.5s;
  ${BREAKPOINTS.XXL} {
    font-size: 96px;
    line-height: 96px;
  }
`;

export const AllSlides = styled.div`
  font-size: 20px;
  transition: all 0.5s;
  ${BREAKPOINTS.XXL} {
    font-size: 36px;
  }
`;

export const CurrentSlide = styled.span`
  font-size: 36px;
  font-family: ${({theme}) => theme.fonts.bold};
`;

export const NextSlide = styled(CurrentSlide)`
/* animation: ${fadeInOut} 2s both; */
  ${BREAKPOINTS.L} {
    font-size: 35px;
  }
  ${BREAKPOINTS.XL} {
    font-size: 35px;
  }
  ${BREAKPOINTS.XXL} {
    font-size: 48px;
  }
`;

export const SlideWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
`;

export const KuulaWrapper = styled.iframe`
  width: 100vw;
  height: 100vh;
`;
