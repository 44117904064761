import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';
import {useMode} from 'context/mode';
import {ColumnWrapper} from './columnwrapper';

import {
  Title2,
  SingleBlock2,
  NumberOfBlock2,
  NumberOfBlockBorder3,
} from './styled';

export const Section3 = (): JSX.Element | null => {
  const {t} = useTranslation();
  const context = useMode();
  const {toggleActiveTab3} = context;

  const {ref, inView} = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    toggleActiveTab3(inView);
  }, [inView]);

  return (
    <div ref={ref}>
      <SingleBlock2>
        <NumberOfBlock2 id="cooperation">03</NumberOfBlock2>
        <NumberOfBlockBorder3>03</NumberOfBlockBorder3>
        <Title2>{t('text.howDoWeSeeOurCooperation')}</Title2>
        <ColumnWrapper />
      </SingleBlock2>
    </div>
  );
};
