import React from 'react';
import {theme} from 'config/theme';
import {ThemeProvider} from 'styled-components';
import {GlobalStyles} from 'styles/global-styles';
import {Content} from 'styles/content';
import {Header} from 'components/header';
import {Navigations} from 'components/navigations';
import {Home} from 'components/home';
import {NavigationsWrapper} from './styled';

import './App.css';
import {ModeProvider} from 'context/mode';
import {Contacts} from 'components/contacts';
import {AboutUs} from 'components/about_us';
import {ClickOutside} from 'components/click-outside';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {useAppSelector} from "./store/hooks";
import {RootState} from "./store";


const App = (): JSX.Element => {

  // FIXME when we remove the line below we have issue with changing languages
  useAppSelector((state: RootState) => state.slide);

  return (
    <ModeProvider>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <ClickOutside />
        <Content>
          <Header />
          <Home />
          <NavigationsWrapper>
            <Navigations />
          </NavigationsWrapper>
        </Content>
        <Contacts />
        <AboutUs />
      </ThemeProvider>
    </ModeProvider>
  );
};

export default App;
