import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {SwiperSlide} from 'swiper/react';
import {changeSlideInfo} from 'store/slice';
import {useAppDispatch} from '../../store/hooks';
import {useMode} from 'context/mode';
import 'swiper/css';

import {Slider} from 'components/slider';
import {Slide} from 'components/slide';
import {GlobalGradient} from 'components/global-gradient';
import {Greetings} from 'components/greetings';
import {useTranslation} from 'react-i18next';
import {SlideContent} from 'components/slide-content';
import i18next from 'i18next';

// @Fixme refactor the duplication
const slides: string[] = [
  'https://kuula.co/share/collection/799vy?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
  'https://kuula.co/share/collection/799vt?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
  'https://kuula.co/share/collection/799nN?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
  'https://kuula.co/share/collection/7999N?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
  'https://kuula.co/share/collection/79994?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
  'https://kuula.co/share/collection/79JPS?logo=-1&fs=0&vr=0&thumbs=-1&inst=0',
];

interface Project {
  id: string;
  title: string;
  subTitle: string;
}

export const Home = (): JSX.Element => {
  const {t} = useTranslation();
  const context = useMode();
  const {current, visible, toggleSpinner} = context;

  const [loading, setLoading] = useState<boolean>(true);
  const [oldLang, setOldLang] = useState<string>(i18next.language);
  const [isLangUpdated, setLangUpdated] = useState<boolean>(false);
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(false);

  const newArray = useMemo(() => {
    const oneSlide = [slides[current]];
    return oneSlide;
  }, [current]);

  useEffect(() => {
    const isIphoneOrIpad = /iPhone|iPad|android/i.test(
      window.navigator.userAgent
    );
    setIsMobileDevice(isIphoneOrIpad);
  }, []);

  const projects = t('projects', {returnObjects: true}) as unknown as Project[];
  const dispatch = useAppDispatch();

  // FIXME Looks like this function can be simpler
  const slidesContent = useMemo(() => {
    const slidesQuantity = slides.length;
    return newArray.map((src) => {
      const nextSlide = current + 1 === slidesQuantity ? 1 : current + 1 + 1;
      const nextSlideId = projects[nextSlide - 1].id;
      const {title, subTitle} = projects[current];
      return {
        title,
        subTitle,
        src,
        slideNumber: current + 1,
        slidesQuantity,
        nextSlide,
        nextSlideId,
        nextSlideTitle: projects[nextSlide - 1].title,
      };
    });
  }, [projects]);

  const onGreetingsEnd = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  i18next.on('languageChanged', () => {
    setLangUpdated(oldLang !== i18next.language);
    setOldLang(i18next.language);
  });

  const onSlideChange = (params: any): void => {
    dispatch(changeSlideInfo(slidesContent[params.realIndex]));
    setLangUpdated(oldLang !== i18next.language);
    toggleSpinner(true);
  };

  interface Prevent3D {
    width: string;
    height: string;
    zIndex: number;
    opacity: number;
    position: 'absolute';
  }

  const prevent3D: Prevent3D = {
    width: '100vw',
    height: '100vh',
    zIndex: 99,
    opacity: 0,
    position: 'absolute',
  };

  return (
    <>
      <Greetings loading={loading} onGreetingsEnd={onGreetingsEnd} />
      {!isMobileDevice && visible ? <div style={prevent3D}></div> : null}
      <SlideContent />
      <GlobalGradient />
      <div>
        <Slider onSlideChange={onSlideChange} isLangUpdated={isLangUpdated}>
          {slidesContent.map((slide) => {
            return (
              <SwiperSlide key={slide.src}>
                <Slide src={slide.src} />
              </SwiperSlide>
            );
          })}
        </Slider>
      </div>
    </>
  );
};
