import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';

interface ViewLanguage {
  view: boolean;
}

export const DropdownMenu = styled.div<ViewLanguage>`
  position: absolute;
  transform: ${(ViewLanguage) =>
    ViewLanguage.view ? 'translateY(-85%)' : 'translateY(-40%)'};
  transition: ${(ViewLanguage) =>
    ViewLanguage.view
      ? 'transform 0.5s, opacity 0.5s'
      : 'transform 0.5s, opacity 0.5s'};
  opacity: ${(ViewLanguage) => (ViewLanguage.view ? '1' : '0')};
  bottom: 25px;
  right: -15px;
  background-color: inherit;
  border: none;
  color: white;
  text-align: left;
  z-index: -10;

  ul {
    list-style-type: none;

    li {
      margin-bottom: 10px;
      height: 25px;
      line-height: 30px;
      opacity: 0.7;
      transition: 0.5s;
      :hover {
        opacity: 1;
        transition: 0.5s;
      }
    }
  }

  ${BREAKPOINTS.L} {
    transform: ${(ViewLanguage) =>
      ViewLanguage.view ? 'translateX(-40%)' : 'translateX(-20%)'};
    transition: ${(ViewLanguage) =>
      ViewLanguage.view
        ? 'transform 0.5s, opacity 0.5s'
        : 'transform 0.5s, opacity 0.5s'};
    opacity: ${(ViewLanguage) => (ViewLanguage.view ? '1' : '0')};
    pointer-events: ${(ViewLanguage) => ViewLanguage.view ? 'all' : 'none'};
    top: 40px;
    text-align: right;
    z-index: 0;
  }
`;
