import styled from 'styled-components';

interface ViewContacts {
  view: boolean;
}

export const ContentWrapper = styled.div<ViewContacts>`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: ${({theme}) => theme.zIndex.low};
  opacity: 0;
  display: ${(ViewContacts) => (ViewContacts.view ? 'block' : 'none')};
  cursor: pointer;
`;
