import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';

interface IProps {
  view: boolean;
}

export const WrapperForCross = styled.div`
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1500;
  ${BREAKPOINTS.XL} {
    div {
      position: fixed;
      right: 40px;
      top: 45px;
    }
  }
`;

export const WrapperForScroll = styled.div<IProps>`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  background-color: black;
  z-index: 2000;
  transform: ${(IProps) =>
    IProps.view ? 'translateY(0)' : 'translateY(100%)'};
  transition: all 0.5s;
`;

export const AboutUsWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: black;
  position: fixed;
  color: ${({theme}) => theme.colors.white};
  z-index: 2000;
  font-size: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 8px;
  scroll-behavior: smooth;
  position: relative;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    position: absolute;
    height: 586px;
    right: 57px;
    top: 354px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    margin-top: 25vh;
    margin-bottom: 25vh;
  }

  ::-webkit-scrollbar-thumb {
    height: 80px;
    right: 57px;
    top: 254px;
    border-radius: 6px;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    ${BREAKPOINTS.L} {
      height: 200px;
    }
    ${BREAKPOINTS.XXL} {
      height: 300px;
    }
    :hover {
      background: white;
    }
  }

  ::-webkit-scrollbar-button {
  }

  transition: all 0.5s;

  ${BREAKPOINTS.S} {
    width: 98vw;
    transition: all 0.5s;
  }

  ${BREAKPOINTS.L} {
    margin-right: 60px;
  }
`;

export const TabsWrapper = styled.div`
  position: absolute;
  width: 28%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightBlocksWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: 4100px;
  margin: 0px 15px;
  padding: 0 2%;
  white-space: normal;
  ${BREAKPOINTS.S} {
    margin-top: 50px;
  }

  ${BREAKPOINTS.M} {
    margin-top: 0px;
  }

  ${BREAKPOINTS.L} {
    margin-top: 100px;
    width: 72%;
  }
  ${BREAKPOINTS.XL} {
    margin-left: 23%;
    width: 77%;
    padding: 0;
    margin-top: -435px;
    max-width: 1100px;
    transform: scale(0.8);
  }
  ${BREAKPOINTS.XXL} {
    transform: scale(1);
    margin-left: 37%;
    margin-top: 100px;
  }
`;

export const WrapperForScrollAndCopy = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 100px;
  ${BREAKPOINTS.XL} {
    margin-bottom: -400px;
  }
  ${BREAKPOINTS.XXL} {
    margin-bottom: 100px;
  }
`;

export const ScrollUp = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: black;
  right: -20px;
  bottom: 90px;
  margin-right: 10%;
  cursor: pointer;
  ${BREAKPOINTS.S} {
    right: -65px;
  }
  ${BREAKPOINTS.M} {
    right: -85px;
  }
  ${BREAKPOINTS.L} {
    display: none;
  }
`;

export const Copyright = styled.div`
  opacity: 0.6;
  font-family: ${({theme}) => theme.fonts.regular};
  font-size: 14px;
  ${BREAKPOINTS.L} {
    margin-left: 350px;
  }
`;
