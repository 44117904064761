import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';
import {
  SingleBlock,
  NumberOfBlock,
  NumberOfBlockBorder,
  Title,
} from '../section1/styled';

export const Separator = styled.div`
  margin-bottom: 40px;
`;

export const ThinText = styled.span`
  color: ${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.regular};
  white-space: normal;
  opacity: 0.7;
`;

export const ColabText = styled.div`
  white-space: normal;
  font-family: ${({theme}) => theme.fonts.bold};
  font-size: 16px;
  opacity: 1;
`;

export const SingleBlock4 = styled(SingleBlock)`
  max-width: 920px;
  margin: 0;
  margin-bottom: 100px;
  margin-top: 150px;
  ${BREAKPOINTS.L} {
    align-items: center;
    margin-bottom: 200px;
  }
`;

export const NumberWrapper = styled.div`
  position: absolute;
  width: 330px;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;
  ${BREAKPOINTS.L} {
    position: relative;
  }
`;

export const NumberOfBlock4 = styled(NumberOfBlock)`
  top: -150px;
  margin: 0;
  display: flex;
  z-index: 0;
  letter-spacing: -0.03em;
  width: 160px;

  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.07),
    rgba(255, 255, 255, 0.03)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${BREAKPOINTS.S} {
    width: fit-content;
  }

  ${BREAKPOINTS.L} {
    position: absolute;
    top: 0;
  }
`;

export const NumberOfBlock4Border = styled(NumberOfBlockBorder)`
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  width: 160px;
  top: -150px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;
  letter-spacing: -0.03em;
  
  ${BREAKPOINTS.S} {
    width: fit-content;
  }
  
  ${BREAKPOINTS.L} {
    position: absolute;
    top: 0;
    -webkit-text-stroke: 2px transparent;
  }
`;

export const ContactText = styled.div`
  max-width: 562px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ContactTitle = styled(Title)`
  max-width: 562px;
  margin: 0;
`;

export const ContactDescr = styled(ColabText)`
  margin-bottom: 40px;
  max-width: 330px;
  ${BREAKPOINTS.S} {
    max-width: 562px;
  }
`;

export const ContactUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${BREAKPOINTS.M} {
    flex-direction: row;
  }
`;

export const ContactUs = styled.div`
  display: flex;
  margin-right: 40px;
  cursor: pointer;
`;
export const ContactIcon = styled.div`
  margin-right: 24px;
  opacity: 0.8;
  transition: all 0.5s;
  :hover {
    opacity: 1;
    transition: all 0.5s;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;
export const ContactData = styled.div`
  font-family: ${({theme}) => theme.fonts.regular};
  font-size: 16px;
  opacity: 0.6;
  transition: all 0.5s;
  margin-bottom: 30px;
  a {
    color: white;
    font-family: ${({theme}) => theme.fonts.regular};
  }
  :hover {
    color: white;
    opacity: 1;
    transition: all 0.5s;
  }
  ${BREAKPOINTS.L} {
    margin-bottom: 0px;
  }


`;
