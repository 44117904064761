import {createSlice} from '@reduxjs/toolkit';
// import type {PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from 'store';

interface SlidesState {
  value: {};
}

const initialState: SlidesState = {
  value: {
    title: '',
    subTitle: '',
    slideNumber: 0,
    slidesQuantity: 0,
    nextSlide: 0,
    nextSlideSrc: '',
    nextSlideTitle: '',
    src: '',
  },
};

const slice = createSlice({
  name: 'slide',
  initialState,
  reducers: {
    changeSlideInfo(state: SlidesState, action) {
      state.value = {...action.payload};
    },
  },
});

export const {changeSlideInfo} = slice.actions;

export const slideInfo = (state: RootState): object => state.slide;

export default slice.reducer;
