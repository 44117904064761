import React, {useEffect, useRef, useState} from 'react';
import {
  MobileCounter,
  DesktopCounter,
  ProgressContent,
  ProgressLine,
  ProgressStatusWrapper,
  ProgressWrapper,
} from './styled';

interface Props {
  onGreetingsEnd: () => void;
}

const Progress = ({onGreetingsEnd}: Props): JSX.Element => {
  const [mobileCount, setMobileCount] = useState<number>(0);
  const [desktopCount, setDesktopCount] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mobileInterval = setInterval(() => {
      setMobileCount((mobileCount) =>
        mobileCount < 100 ? mobileCount + 1 : mobileCount
      );
    }, 55);
    return () => clearInterval(mobileInterval);
  }, []);

  useEffect(() => {
    const desktopInterval = setInterval(() => {
      setDesktopCount((desktopCount) =>
        desktopCount < 100 ? desktopCount + 1 : desktopCount
      );
    }, 80);
    return () => clearInterval(desktopInterval);
  }, []);

  useEffect(() => {
    ref.current?.addEventListener('animationend', onGreetingsEnd, false);

    return () =>
      ref.current?.removeEventListener('animationend', onGreetingsEnd);
  }, []);

  return (
    <ProgressWrapper>
      <ProgressStatusWrapper>
        <MobileCounter >{mobileCount}%</MobileCounter>
        <DesktopCounter >{desktopCount}%</DesktopCounter>
      </ProgressStatusWrapper>
      <ProgressContent>
        <ProgressLine count={desktopCount} ref={ref} />
      </ProgressContent>
    </ProgressWrapper>
  );
};

export {Progress};
