import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';
import {
  NumberOfBlock,
  NumberOfBlockBorder,
  SingleBlock,
  Title,
} from '../section1/styled';

interface IProps {
  view: boolean;
}

export const SingleBlock2 = styled(SingleBlock)`
  flex-direction: column;
  justify-content: space-between;
  max-width: 1100px;

  ${BREAKPOINTS.M} {
    margin-top: 345px;
    max-width: 1100px;
  }
`;

export const NumberOfBlockBorder3 = styled(NumberOfBlockBorder)`
  margin-top: 10px;
  width: 160px;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px transparent;

  ${BREAKPOINTS.S} {
    margin-top: 40px;
    width: fit-content;
  }
  ${BREAKPOINTS.M} {
    margin-top: 190px;
  }
`;

export const NumberOfBlock2 = styled(NumberOfBlock)`
  margin-top: 10px;
  width: 160px;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.03)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${BREAKPOINTS.S} {
    margin-top: 40px;
    width: fit-content;
  }
  ${BREAKPOINTS.M} {
    margin-top: 190px;
  }
`;

export const Title2 = styled(Title)`
  max-width: 330px;
  ${BREAKPOINTS.S} {
    max-width: 530px;
  }
  ${BREAKPOINTS.M} {
    max-width: inherit;
  }
`;

export const ThinText = styled.span`
  color: ${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.regular};
  white-space: normal;
  opacity: 0.7;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  max-width: 90vw;
  ${BREAKPOINTS.M} {
    flex-direction: row;
  }
`;

export const NumWithDots = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 310px;
  margin-right: 19px;
  margin-bottom: 40px;
  svg {
    margin-right: 19px;
  }
  ${BREAKPOINTS.M} {
    flex-direction: row;
  }
  ${BREAKPOINTS.L} {
    max-width: 350px;
  }
`;

export const ColabNum = styled.div`
  font-size: 48px;
  font-family: ${({theme}) => theme.fonts.bold};
  background: linear-gradient(to bottom, #4e9fff 0%, #de11ff 100%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 4px transparent;
  -webkit-text-fill-color: black;
  height: 90px;
  line-height: 90px;

  ${BREAKPOINTS.S} {
    font-size: 96px;
  }
`;

export const Column = styled.div<IProps>`
  max-width: 650px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  transition: ${(IProps) =>
    IProps.view ? 'transform 1.5s, opacity 3s' : 'none'};
  transition-delay: 500ms;
  transform: ${(IProps) =>
    IProps.view ? 'translateX(0)' : 'translateX(350%)'};
  opacity: ${(IProps) => (IProps.view ? '1' : '0')};
  ${BREAKPOINTS.M} {
    flex-direction: column;
    width: 650px;
  }
`;

export const Column2 = styled(Column)<IProps>`
  transition-delay: 1500ms;
`;

export const Column3 = styled(Column)<IProps>`
  transition-delay: 2500ms;
`;

export const CooperationText = styled.div`
  margin-bottom: 30px;
  ${BREAKPOINTS.L} {
    margin: 0;
  }
`;

export const WrapperForTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  ${BREAKPOINTS.M} {
    height: 150px;
  }
  ${BREAKPOINTS.L} {
    height: 170px;
  }
`;

export const ColabTitle = styled.div`
  white-space: normal;
  font-family: ${({theme}) => theme.fonts.bold};
  font-size: 24px;
  line-height: 30.41px;
  margin-bottom: 15px;
`;

export const ColabText = styled.div`
  white-space: normal;
  font-family: ${({theme}) => theme.fonts.bold};
  font-size: 16px;
  opacity: 1;
`;
