import React from 'react';
import {ContentWrapper} from './styled';
import {useMode} from 'context/mode';

export const ClickOutside = (): JSX.Element => {
  const context = useMode();
  const {viewContacts} = context;

  return (
    <>
      <ContentWrapper view={viewContacts} />
    </>
  );
};
