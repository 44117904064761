import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/breakpoints';

interface Props {
  zoom: boolean
}

export const KuulaWrapper = styled.iframe<Props>`
  width: 100vw;
  height: 100vh;
  transition: all 0.5s;
  ${BREAKPOINTS.L} {
    transform: ${(Props)=> Props.zoom ? 'scale(1)' : 'scale(1.2)'};
  }
`;
