import React, {createContext, useContext, useState} from 'react';

interface ContextValue {
  isViewKuula: boolean;
  toggleViewKuula: (value?: boolean) => void;
  viewContacts: boolean;
  toggleContacts: (value?: boolean) => void;
  viewAboutUs: boolean;
  toggleAboutUs: (value?: boolean) => void;
  activeTab1: boolean;
  toggleActiveTab1: (value?: boolean) => void;
  activeTab2: boolean;
  toggleActiveTab2: (value?: boolean) => void;
  activeTab3: boolean;
  toggleActiveTab3: (value?: boolean) => void;
  activeTab4: boolean;
  toggleActiveTab4: (value?: boolean) => void;
  activeTab: number;
  changeActiveTab: (value?: number) => void;
  view: boolean;
  viewLanguage: (value?: boolean) => void;
  visible: boolean;
  changeVisible: (value?: boolean) => void;
  viewSlideContent: boolean;
  toggleSlideContent: (value?: boolean) => void;
  current: number;
  setNextSlide: (value: string[]) => void;
  setPrevSlide: (value: string[]) => void;
  viewSpinner: boolean;
  toggleSpinner: (value?: boolean) => void;
  visibleMobileHint: boolean;
  changeVisibleMobileHint: (value?: boolean) => void;
  nextSlideOnPreview: boolean;
  toggleNextSlide: (value?: boolean) => void;
}

const ModeContext = createContext<ContextValue>({
  isViewKuula: false,
  toggleViewKuula: () => {},
  viewContacts: false,
  toggleContacts: () => {},
  viewAboutUs: false,
  toggleAboutUs: () => {},
  activeTab1: false,
  toggleActiveTab1: () => {},
  activeTab2: false,
  toggleActiveTab2: () => {},
  activeTab3: false,
  toggleActiveTab3: () => {},
  activeTab4: false,
  toggleActiveTab4: () => {},
  activeTab: 1,
  changeActiveTab: () => {},
  view: false,
  viewLanguage: () => {},
  visible: true,
  changeVisible: () => {},
  viewSlideContent: true,
  toggleSlideContent: () => {},
  current: 0,
  setNextSlide: () => {},
  setPrevSlide: () => {},
  viewSpinner: false,
  toggleSpinner: () => {},
  visibleMobileHint: true,
  changeVisibleMobileHint: () => {},
  nextSlideOnPreview: false,
  toggleNextSlide: () => {},
});

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const ModeProvider = ({children}: Props): JSX.Element => {
  const [isViewKuula, setViewKuula] = useState<boolean>(false);
  const [viewContacts, setViewContacts] = useState<boolean>(false);
  const [viewAboutUs, setViewAboutUs] = useState<boolean>(false);
  const [activeTab1, setActiveTab1] = useState<boolean>(false);
  const [activeTab2, setActiveTab2] = useState<boolean>(false);
  const [activeTab3, setActiveTab3] = useState<boolean>(false);
  const [activeTab4, setActiveTab4] = useState<boolean>(false);
  const [view, setView] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [visible, setVisible] = useState<boolean>(true);
  const [viewSlideContent, setSlideContent] = useState<boolean>(true);
  const [current, setCurrent] = useState<number>(0);
  const [viewSpinner, setViewSpinner] = useState<boolean>(false);
  const [visibleMobileHint, setVisibleMobileHint] = useState<boolean>(true);
  const [nextSlideOnPreview, setNextSlideOnPreview] = useState<boolean>(false);

  const toggleContacts = (value?: boolean): void => {
    setViewContacts((prevState) => (value === undefined ? !prevState : value));
  };

  const toggleAboutUs = (value?: boolean): void => {
    setViewAboutUs((prevState) => (value === undefined ? !prevState : value));
  };

  const toggleActiveTab1 = (value?: boolean): void => {
    setActiveTab1((prevState) => (value === undefined ? !prevState : value));
  };

  const toggleActiveTab2 = (value?: boolean): void => {
    setActiveTab2((prevState) => (value === undefined ? !prevState : value));
  };

  const toggleActiveTab3 = (value?: boolean): void => {
    setActiveTab3((prevState) => (value === undefined ? !prevState : value));
  };
  const toggleActiveTab4 = (value?: boolean): void => {
    setActiveTab4((prevState) => (value === undefined ? !prevState : value));
  };

  const toggleViewKuula = (value?: boolean): void => {
    setViewKuula((prevState) => (value === undefined ? !prevState : value));
  };

  const viewLanguage = (value?: boolean): void => {
    setView((prevState) => (value === undefined ? !prevState : value));
  };

  const changeVisible = (value?: boolean): void => {
    setVisible((prevState) => (value === undefined ? !prevState : value));
  };

  const toggleSlideContent = (value?: boolean): void => {
    setSlideContent((prevState) => (value === undefined ? !prevState : value));
  };

  const changeActiveTab = (): void => {
    if (activeTab1) {
      return setActiveTab(1);
    } else if (activeTab2) {
      return setActiveTab(2);
    } else if (activeTab3) {
      return setActiveTab(3);
    } else if (activeTab4) {
      return setActiveTab(4);
    }
  };

  const setNextSlide = (value: string[]): void => {
    const nextIdx = (current + 1) % value.length;
    setCurrent(nextIdx);
  };
  const setPrevSlide = (value: string[]): void => {
    const nextIdx = (current - 1 + value.length) % value.length;
    setCurrent(nextIdx);
  };

  const toggleSpinner = (value?: boolean): void => {
    setViewSpinner((prevState) => (value === undefined ? !prevState : value));
  };

  const changeVisibleMobileHint = (value?: boolean): void => {
    setVisibleMobileHint((prevState) =>
      value === undefined ? !prevState : value
    );
  };

  const toggleNextSlide = (value?: boolean): void => {
    setNextSlideOnPreview((prevState) =>
      value === undefined ? !prevState : value
    );
  };

  return (
    <ModeContext.Provider
      value={{
        isViewKuula,
        toggleViewKuula,
        viewContacts,
        toggleContacts,
        viewAboutUs,
        toggleAboutUs,
        activeTab1,
        activeTab2,
        activeTab3,
        activeTab4,
        toggleActiveTab1,
        toggleActiveTab2,
        toggleActiveTab3,
        toggleActiveTab4,
        viewLanguage,
        view,
        activeTab,
        changeActiveTab,
        visible,
        changeVisible,
        viewSlideContent,
        toggleSlideContent,
        current,
        setNextSlide,
        setPrevSlide,
        viewSpinner,
        toggleSpinner,
        visibleMobileHint,
        changeVisibleMobileHint,
        nextSlideOnPreview,
        toggleNextSlide
      }}
    >
      {children}
    </ModeContext.Provider>
  );
};

export const useMode = (): ContextValue => useContext(ModeContext);
