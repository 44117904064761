import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';

import {
  WhyWe,
  Benefits,
  Benefits2,
  Benefits3,
  Number,
  TextAroundNum,
  TextAroundNumRight,
  Seven,
  Vr360,
} from './styled';

export const WhyWeWrapper = (): JSX.Element | null => {
  const [count1, setCount1] = useState<number>(0);
  const [count2, setCount2] = useState<number>(0);
  const [count3, setCount3] = useState<number>(0);

  const {t} = useTranslation();

  const {ref, inView} = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (inView) {
        setCount1((count1) => (count1 < 90 ? count1 + 1 : count1));
      } else {
        setCount1(0);
      }
    }, 25);
    return () => clearInterval(interval);
  }, [inView]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (inView) {
        setCount2((count2) => (count2 < 50 ? count2 + 1 : count2));
      } else {
        setCount2(0);
      }
    }, 35);
    return () => clearInterval(interval);
  }, [inView]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (inView) {
        setCount3((count3) => (count3 < 24 ? count3 + 1 : count3));
      } else {
        setCount3(0);
      }
    }, 45);
    return () => clearInterval(interval);
  }, [inView]);

  return (
    <div ref={ref}>
      <WhyWe>
        <Benefits>
          <TextAroundNum>{t('text.by')}</TextAroundNum>
          <div>
            <Number>{count1}</Number>
          </div>
          <TextAroundNumRight>{t('text.accelerated')}</TextAroundNumRight>
        </Benefits>
        <Benefits2>
          <TextAroundNum>{t('text.by')}</TextAroundNum>
          <Number>{count2}</Number>
          <TextAroundNumRight>{t('text.reduced')}</TextAroundNumRight>
        </Benefits2>
        <Benefits3>
          <Vr360>{t('text.access')}</Vr360>
          <Number>{count3}</Number>
          <Seven>/7</Seven>
        </Benefits3>
      </WhyWe>
    </div>
  );
};
