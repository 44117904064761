import styled from 'styled-components';
import {BREAKPOINTS} from 'styles/breakpoints';
import {
  SingleBlock,
  NumberOfBlock,
  NumberOfBlockBorder,
  Title,
} from '../section1/styled';

export const SingleBlock2 = styled(SingleBlock)`
  display: block;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1000px;

  ${BREAKPOINTS.M} {
    display: none;
  }
`;

export const NumberOfBlockBorder2 = styled(NumberOfBlockBorder)`
  margin-top: 25px;
  width: 160px;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px transparent;

  ${BREAKPOINTS.S} {
    margin-top: 20px;
    width: fit-content;
  }
`;

export const NumberOfBlock2 = styled(NumberOfBlock)`
  margin-top: 25px;
  width: 160px;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.03)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${BREAKPOINTS.S} {
    margin-top: 20px;
    width: fit-content;
  }
`;

export const Title2 = styled(Title)`
  margin-top: 155px;
`;

export const Separator = styled.div`
  margin-bottom: 40px;
`;

export const ThinText = styled.span`
  color: ${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.regular};
  white-space: normal;
  opacity: 0.7;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  max-width: 90vw;
  ${BREAKPOINTS.M} {
    flex-direction: row;
  }
`;

export const WhatWeOffer = styled.div`
  max-width: 90vw;
  white-space: normal;
  font-family: ${({theme}) => theme.fonts.bold};
  font-size: 16px;
  opacity: 1;
  line-height: 26px;
  margin-right: 10px;
  ${BREAKPOINTS.XL} {
    width: 443px;
    margin-right: 138px;
  }
`;


export const WhatWeOfferBottom2 = styled(WhatWeOffer)`
  margin-bottom: 0px;
`;
