import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Menu, TabWrapper, Marker} from './styled';
import {useMode} from 'context/mode';
import {ReactComponent as Dot} from 'assets/icons/circle_highlight.svg';

export const Tabs = (): JSX.Element | null => {
  const {t} = useTranslation();
  const context = useMode();
  const {
    activeTab1,
    activeTab2,
    activeTab3,
    activeTab4,
    changeActiveTab,
    activeTab,
  } = context;

  const toggleActive = {
    opacity: 1,
  };

  const disableActiveTab = {
    opacity: 0.46,
  };

  useEffect(() => {
    changeActiveTab();
  }, [activeTab1, activeTab2, activeTab3, activeTab4]);

  return (
    <>
      <Menu>
        <TabWrapper>
          <Marker active={activeTab}>
            <Dot />
          </Marker>
          <ul>
            <li style={activeTab === 1 ? toggleActive : disableActiveTab}>
              <a href="#who-are-we">{t('text.whoAreWe')}</a>
            </li>
            <li style={activeTab === 2 ? toggleActive : disableActiveTab}>
              <a href="#whatweoffer">{t('text.whatDoWeOffer')}</a>
            </li>
            <li style={activeTab === 3 ? toggleActive : disableActiveTab}>
              <a href="#cooperation">{t('text.howDoWeSeeOurCooperation')}</a>
            </li>
            <li style={activeTab === 4 ? toggleActive : disableActiveTab}>
              <a href="#contactus">{t('text.contactUs')}</a>
            </li>
          </ul>
        </TabWrapper>
      </Menu>
    </>
  );
};
