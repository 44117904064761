// import {Dropdown} from 'antd';
import React, {useEffect, useRef} from 'react';
import {useMode} from 'context/mode';
import {useTranslation} from 'react-i18next';
import {DropdownMenu} from './styled';

export const LanguageSelect = (): JSX.Element => {
  const {i18n} = useTranslation();

  const context = useMode();
  const {view, viewLanguage} = context;

  const Ukr = (e: React.MouseEvent): void => {
    void i18n.changeLanguage('ua');
  };
  const Eng = (e: React.MouseEvent): void => {
    void i18n.changeLanguage('en');
  };

  const ref = useRef<HTMLDivElement>(null);

  // @FIXME Refactor the method without ids using best practices
  useEffect(() => {
    const modal = (e: UIEvent): void => {
      e.stopPropagation();
      const {target} = e;
      const targetElement: Node = e.target as Node;
      const targetElement2: HTMLElement = e.target as HTMLElement;
      if (
        ref.current !== null &&
        !ref.current.contains(target as Node) &&
        targetElement.nodeName !== 'UL' &&
        targetElement.nodeName !== 'LI' &&
        ['navigation_item', 'language_arrow', 'arrow_up'].every(
          (id) => id !== targetElement2.id
        )
      ) {
        viewLanguage(false);
      }
    };
    window.addEventListener('mousedown', modal);

    return (): void => {
      window.removeEventListener('mousedown', modal);
    };
  }, []);

  return (
    <>
      <DropdownMenu view={view} ref={ref}>
        <ul>
          <li onClick={Ukr}>Українська</li>
          <li onClick={Eng}>English</li>
        </ul>
      </DropdownMenu>
    </>
  );
};
