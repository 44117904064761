import React, {useState, useEffect} from 'react';
import {Navigations} from 'components/navigations';
import {HeaderWrapper, LogoWrapper, NavigationWrapper} from './styled';
import {TwinsLogo} from 'components/twins-logo';
import {useMode} from 'context/mode';

export const Header = (): JSX.Element => {
  const context = useMode();

  const {visibleMobileHint} = context;

  const [logoPosition, setLogoPosition] = useState<boolean>(false);

  useEffect(() => {
    const interval = setTimeout(() => {
      setLogoPosition(true);
    }, 8350);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <LogoWrapper position={logoPosition} animate={visibleMobileHint}>
        <TwinsLogo />
      </LogoWrapper>
      <HeaderWrapper data-testid="header">
        <NavigationWrapper>
          <Navigations />
        </NavigationWrapper>
      </HeaderWrapper>
    </>
  );
};
