import React, {CSSProperties, useRef} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
  GradientText,
  GreetingsText,
  GreetingsWrapper,
  ProgressWrapper,
} from './styled';
import {Progress} from 'components/progress';
import {Transition} from 'react-transition-group';

interface Props {
  onGreetingsEnd: () => void;
  loading: boolean;
}

const duration = 400;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in`,
  opacity: 1,
};

const transitionStyles: Record<string, CSSProperties> = {
  entering: {opacity: 1},
  entered: {opacity: 1},
  exiting: {opacity: 0},
  exited: {opacity: 0, zIndex: -1},
};

const Greetings = ({onGreetingsEnd, loading}: Props): JSX.Element | null => {
  const {t} = useTranslation();
  const ref = useRef(null);

  return (
    <Transition in={loading} nodeRef={ref} timeout={duration}>
      {(state) => (
        <GreetingsWrapper
          loading={loading}
          ref={ref}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <GreetingsText>
            <Trans
              t={t}
              i18nKey="text.intro"
              components={[<GradientText key={0} />]}
            />
          </GreetingsText>
          <ProgressWrapper>
            <Progress onGreetingsEnd={onGreetingsEnd} />
          </ProgressWrapper>
        </GreetingsWrapper>
      )}
    </Transition>
  );
};

Greetings.displayName = 'Greetings';

export {Greetings};
